.review-analyis{
    width: 100%;
    background: white;
    border-radius: 8px;
    display: flex;
    box-shadow: 0px 7px 20px rgba(0, 0, 0,  0.2);
    height: 140px;
}
@media(max-width:1435px){
    .review-analyis{
       flex-wrap: wrap;
       justify-content: center;
       height: fit-content;
    }
    div.counter-student-container{
        padding-bottom: 10px;
    }
   }
.analyis-header{
    width: 170px;
    display: flex;
    max-height: 139px;
    
}
.completion-rate{
    width:25%
}
.completion-rate p{
    font-family: 'Poppines', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #B7B7BC;
    position: relative;
    padding: 20px 20px 0 20px;
}

.completion-rate p:last-child{
    color: #212529;
    position: relative;
    padding: 7px 14px;
    font-family: 'Poppines', sans-serif;
    font-weight: bold;
    font-size: 18px;

}
.total-student{
    display: flex;
    padding-top: 25px;
    position: relative;
    flex-direction: row;
    align-items: baseline;
    max-width: 433px;
    margin: 0 auto;

}
.total-student p{
    margin:0;
    padding:0;
    text-align: center;
}
.total-student p:first-child{
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    font-size:18px;
    width: 30%;
    text-align: start;
}
.total-student p:last-child{
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    font-size:16px;
    color:#B7B7BC;
    position: relative;  
    width: 23%; 
    text-align: end;
}
.counter-student-container{
    width:100%;
    padding:0 10px;
}
.compelete-ex,.not-compeleted-ex,.view-ex{
    max-width: 450px;
    justify-content: center;
    padding:2px 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.complete-ex-text{
    width:30%;
    margin:0;
    padding:0;
    font-family: 'Poppins', sans-serif;
    font-size:14px;
    color:#495057;
    font-weight: 400;
}
.complete-ex-value{
    width:20%;
    margin:0;
    padding:0;
    font-family: 'Poppins', sans-serif;
    font-size:14px;
    color:#495057;
    font-weight: 600;
    text-align: end;
}
.complete-ex-liner-container{
    width: 45%;
    padding-left: 4px;
   
}
.compelte-ex-liner-chart{
   
    height: 10px;
    border-radius: 5px;
    background: #34C38F;
    transition: all 0.3s ease-in-out;
}
.not-compelte-ex-liner-chart{
   
    height:10px;
    border-radius: 5px;
    background: #F46A6A;
    transition: all 0.3s ease-in-out;
}
.view-ex-liner-chart{
   
    height: 10px;
    border-radius: 5px;
    background: #556EE6;
}