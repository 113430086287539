.admin-table-container .MuiTableCell-head{
    font-family: 'Poppins' !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    text-align: center !important;
}
.admin-table-container .MuiTableCell-body{
    font-family: 'Poppins' !important;
    font-size: 13px !important;
    text-align: center !important;
}
.admin-table-container .MuiTableCell-root{
    padding:8px 0 !important;
    width: 20px;
}
.admin-table-container .MuiTableCell-root .PrivateSwitchBase-root-9{
    padding: 9px 0;
}

.MuiTableCell-head, .MuiTableCell-root{
    padding: 13px 2px;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
 
}
@media (max-width: 1300px) {
    body .MuiTableCell-head, body .MuiTableCell-root{
        max-width:calc(100px + (160 - 100) * ((100vw - 1024px) / (1300 - 1024))) ;
    }
 }
 @media (max-width: 1024px) {
    body .MuiTableCell-head, body .MuiTableCell-root{
        max-width:100px ;
    }
 }
.admin-table-container .MuiTableRow-root .admin-name-table{
    border-bottom: 0;
    text-align: start !important;

}
.admin-table-container .MuiTableRow-root .admin-table-name-title{
    text-align: left !important;
    padding-left: 20px !important;
}