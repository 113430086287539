.photo-submission-container{
    width: 100%;
    background: white;
    max-height: 95vh;
    margin-top: 18px;
    border-radius: 8px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0,  0.2);
    padding: 0 10px;
    overflow-x: hidden;
    overflow-y: scroll;

   
}
.photo-submission-container::-webkit-scrollbar {
    width: 18px; 
    height: 10px; 
    border-radius: 8px;
  }
  .photo-submission-container::-webkit-scrollbar-thumb {
    background: #fff;
    border: 1px solid #EAEAEA;
    width: 16px;
    border-radius: 8px;
  }
  .photo-submission-container::-webkit-scrollbar-track { 
    background: #F8F8FB;
    box-shadow: inset -2px 3px 5px rgba(0,0,0,0.16);
    width: 18px;
    border-radius: 8px;
  }
.photo-submission-title{
    text-align: center;
    font-family: 'Poppines', sans-serif;
    font-weight: 500;
    font-size: 44px;
    padding-top: 6px;
}
@media screen and (max-width:1300px) {
    .photo-submission-title{
        font-size: 30px;
    }
}
.photo_container{
    width: 100%;
    padding: 8px 0px;
    min-height: 257px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    
}
.photo-item-container{
    width: 35%;
    max-width: 225px;
    min-width: 210px;
    margin-bottom: 5px;
    max-height: 300px;
    position: relative;
    margin-right: 5px;

}
.photo-item-container > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
    background-color: black;

    cursor: pointer;
   
}
.photo-item-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: rgba(0,0,0,0.3);
    border-radius: 5px;
    justify-content: space-between;
    cursor: pointer;
}
.photo-item-control{
    width:100%;
    position: absolute;
    z-index: 10;
    display: flex;
    top: 0;
    justify-content: space-between;

}
.photo-item-profile{
    display: flex;
    position: absolute;
    bottom: 0;
    padding-top: 35px;
    height: 116px;
}
.photo-item-profile p{
    color: white;
    text-align: left;
    font-family: "Poppins" , 'sans-serif';
    font-weight: 900;
    text-transform: capitalize;
    font-size: 16px;
    padding-left: 76px;
    padding-top: 24px;
    overflow: hidden;
    max-width: 208px;
    white-space: nowrap;
    text-overflow: ellipsis;
   
}
