.student-filter-container{
    width: 180px;
    height: 30px;
    margin-left: 28%;
    background: #707070;
    border-radius: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.student-filter-container .all,.student-filter-container .account{
     flex:1;
    text-align: center;
    
    height: 100%;
    border-radius: 50px;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5 ease-in-out;
    position: relative;
    cursor: pointer;
}
.student-filter-container .account::before{
    content: 'Account';
    display: block;
    color:white;
    position: absolute;
    z-index:10;
    font-size: 14px;
}
.student-filter-container .all::before{
    content: 'ALL';
    display: block;
    color:white;
    position: absolute;
    z-index:10;
    font-size: 14px;
}
.stuActive{
    width:50%;
    height: 100%;
    position:absolute;
    background: rgba(0,0,0,.5);
    top:0;
    left:0;
    border-radius: 50px;
    z-index:9;
    transition: all 0.3s ease-in-out;
}