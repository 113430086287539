

.dropdown-box {
     display: none;
     width: 160px;
     height: fit-content;
     position: absolute!important;
     top: 50px;
     background: white;
     padding: 10px;
     border: 1px solid rgb(199, 204, 207);
     border-radius: 5px;
     z-index: 10;
     
}


.dropdown-box ul {
     display: flex;
     flex-flow: column;
     padding: 0;
     margin: 0;
    

}
.dropdown-box ul li{
    margin: 3px;

}
.dropdown-box ul button{
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    color: #757575;
    white-space: none;
    transition: .1s ease-in;

    
}

.dropdown-box ul button:hover{
    color: #000;
    
}

.open {
    display: block;
}