.gradegy-dropdown-container {
    text-align: left;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 5px;
  }
  
  .gradegy-dropdown-selected-value{
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;
    color: #495057;
  }

  .gradegy-dropdown-input {
    padding: 3px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
  }
  
  .gradegy-dropdown-menu {
    position: absolute;
    transform: translateY(4px);
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
    max-height: 150px;
    z-index: 2;
    background: #fff;
  }
  
  .gradegy-dropdown-item {
    padding: 5px 7px;
    cursor: pointer;
    font-size: 14px;
    font-style: italic;
    line-height: 21px;
    font-weight: 400;
    color: #495057;
  }
  
  .gradegy-dropdown-item:hover {
    background-color: #9fc3f870;
  }
  
  .gradegy-dropdown-item.selected {
    background-color: #0d6efd;
    color: #fff;
  }
  
  .gradegy-dropdown-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .gradegy-dropdown-tag-item {
    background-color: #ddd;
    padding: 2px 4px;
    border-radius: 2px;
    display: flex;
    align-items: center;
  }
  
  .gradegy-dropdown-tag-close {
    display: flex;
    align-items: center;
  }
  
  .search-box {
    padding: 5px 5px 0;
  
  }
  
  .search-box input:focus-visible {
    outline: none;

  }


  .search-box-container{
    height: 30px;
    position: relative;
    margin-left: 0px;
  }
  .search-box-input{
    border-radius: 50px;
    border: 1px solid #CED4DA;
    padding-left: 20px;
    text-indent: 10px;
    font-size: 11px;
    line-height: 20px;
    width: 94%;

  }
  