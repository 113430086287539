.pic-exp-container{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background:rgba(0,0,0,0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.pic-panel{
    width: 50%;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding:40px;
    background:white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 80vh;
    overflow-y: scroll;
    position: relative;
    
}
.pic-item{
    width:33.333%;
    text-align: center;
    cursor: pointer;
    margin:0 10px;
}
.active{
    border:4px solid green;
    border-radius: 5px;
}
.control-button{
    background:#f7f7fa;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    
}
.control-button button{
    padding:8px 50px;
    color:white;
    background: #0078FF;
    border:none;
    border-radius: 50px;
    margin-right: 50px;

}
.picexp{
    width: 300px ;
}
.pic-panel-header{
    text-align: center;
    width: 100%;
}
.image-control{
   background: white;
   width: 50%;
   display: flex;
   justify-content: center;
   border-bottom-right-radius: 15px;
   border-bottom-left-radius: 15px;
   padding:30px 0;
}
.image-control .save,.image-control .cancel{
    padding:15px 50px;
    background-color: #00C4B8;
    color:white; 
    border:none;
    outline: none;
    border-radius: 50px;
    font-family: "Roboto","sans-serif";
    font-weight: 600;
    margin:0 30px;
}
.image-control .cancel{
    background:#FF4B00
    
}