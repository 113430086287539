.switch{
    width: 43px;
    height: 19px;
    position: relative;
    
}

.switchInput{
    opacity: 0;
    width:0;
    height: 0;
}
.switchInput:checked + span::before {
    transform: translateX(21px);
}
.switchInput:checked + span {
    background:green
}
.slider{
    position: absolute;
    cursor: pointer;
    top:0;
    left: 0;
    width: 43px;
    right:0;
    bottom: 0;
    background: red;
    border-radius: 50px;
    transition: all 0.4s ease-in-out;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.slider span{
    padding-left: 5px;
    line-height: 11px;
    flex: 1 1;
    color: white;
    font-family: "Poppins";
    font-size: 8px;
}

.slider::before{
 
    position: absolute;
    content: "";
    left: 3px;
    bottom: 2px;
    width: 16px;
    height: 16px;
    background: white;
    transition: all 0.4s ease-in-out;
    border-radius: 50px;
}

