.track{
    stroke: #f7f7fa;
    stroke-width: 30;
    fill:none;
}
.progress{
    fill:none;
    stroke-width: 30;
    /*stroke: #34C38F;*/
    
    stroke-linecap: round;
    stroke-dasharray: 495;
    stroke-dashoffset: 495;
    transition: all 0.3s ease-in-out;

}
.circle-chart h2{
    position: absolute;
    top: 95px;
    left: 76px;
    text-align: center;
    width: 98px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 48px;
}
.circle-chart h4.circle-title{
    position: absolute;
    left: 0;
    min-width: 85%;
    top: 233px;
    padding: 0 13%;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 20px !important;
}
.circle-chart .circle-title-container{
    position: absolute;
    left: 0;
    top: 233px;
    width: 90%;
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 600;
    font-size:
     20px !important;
}
.circle-chart .circle-title-container h4{
    color: #B7B7BC;
    font-size: 22px;
    font-family: "Rajdhani";
    font-weight: bold;
}
.circle-chart .circle-title-container h3{
    color: #3E3F5E;
    font-size: 26px;
    font-family: "Rajdhani";
    font-weight: bold;

}
.circle-chart.text-position-top h4{
    top: 0;
}
.score{
    position: absolute;
    left:101px !important;
    top:267px !important;
    font-family: 'Poppins';
    font-size: 22px !important;
    font-weight: 600;
    color: #B7B7BC;
}

.student-name{
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 44;
    color: #495057;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    
}
.student-name-container{
    display: flex;
    width: 100%;
    justify-content: center; 
    position: relative;
}
.student-name-container h3{
    font-size: 32px;
    font-family: 'Poppins';
    color: #495057;
    position: absolute;
    left: 0;
    bottom: 0;
    font-weight: 400;
}
.student-name h1{
    font-family: "Rajdhani";
    font-size: 95px;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 4px;
}
.king-icon{
    position: absolute;
    top: 15px;
    left: 168px;
}
.numberic-circle{
    position: absolute;
    top: 24px;
    left: 179px;
   
  
}
.numberic-circle span{
    font-size: 0;
    font-family: "Rajdhani";
    font-weight: bold;
    background-color: #ff0000;
    border-radius: 50%;
    padding: 0;
    color: #f7f7fa;
    transition-duration: 1000ms;
}
.numberic-circle .show-numbric-popup{
    font-size: 23px;
    padding: calc(50% - 30px / 2) 13px;
}
.profile-level{
    position: absolute;
    top: 89px;
    left: 76px;
    text-align: center;
    width: 98px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 60px;
}
.profile-level h3{
    font-size: 27px;
    font-family: "Rajdhani";
    font-weight: bold;
}
.profile-level h3 span{
    font-size:40px;
    font-family: "Rajdhani";
    font-weight: bold;
}
.profile-level h5{
    font-size: 24px;
    font-family: "Rajdhani";
    font-weight: bold;
}
