.pagination {
    background-color: none;
    border: none;
    outline: none;
    margin-top: 10px;
}
ul {
    list-style: none;
}
ul.pagination li {
   background: none;
   margin-right: 10px;
}
ul.pagination li a {
    background:none;
    outline: none;
    border: none;
    font-weight: bold;
    color: black;
    
}
ul.pagination li a:hover {
    color: rgb(39, 39, 39);
    border-radius: 5px;
    background-color: rgb(117, 214, 178);
}


ul.pagination .active{
    background: white;
    border: none;
}

ul.pagination li.active .page-link {
    background-color: rgb(52,195,143);
    border: white!important;
    border-radius: 5px;
}
ul.pagination li.active .page-link:hover {
    background-color: rgb(52,195,143);
}


