.ex-student-list-container{
    width:100%;
    min-height: 300px;
    background: white;
    margin-top: -10px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0,  0.2);
    border-radius: 10px;
    display: inline-block;
    max-height: 94.7vh;
    overflow-y: scroll;
}
.ex-student-list-container::-webkit-scrollbar {
    width: 8px; 
    height: 10px; 
    border-radius: 8px;
  }
  .ex-student-list-container::-webkit-scrollbar-thumb {
    background: #fff;
    border: 1px solid #EAEAEA;
    width: 6px;
    border-radius: 8px;
  }
  .ex-student-list-container::-webkit-scrollbar-track { 
    background: #F8F8FB;
    box-shadow: inset -2px 3px 5px rgba(0,0,0,0.16);
    width: 8px;
    border-radius: 8px;
  }
.ex-student-list-title{
    text-align: center;
    font-family: 'Rajdhani', sans-serif;
    font-size: 36px;
    font-weight: 900;
    padding-top: 10px;
}
.ex-student-list-table{
    width:100%;
    margin-top: 15px;
}
.ex-student-list-table .MuiSvgIcon-root{
    width: 17px;
    color: #ababab;
}
.ex-student-list-table .MuiTableCell-root{
    padding: 3px;
}
.ex-student-list-table .MuiTable-root{
    width: 98%;
    margin-left: 1%;
}
.student-list-ex-com-not{
    color:#F70606;
    font-family: 'Poppins', sans-serif;
    font-family: 14px;
    font-weight: 600;

}
.student-list-ex-com{
    color:green;
    font-family: 'Poppins', sans-serif;
    font-family: 14px;
    font-weight: 600;
}
.item-student-name-ex{
    font-family: 'Poppins', sans-serif;
    font-family: 14px;
    font-weight: 600;
    margin: 0;
}