.driver {
    display: flex;
    flex-flow: column;
    margin-left: 30px;
    margin-right: 30px;
    padding-right: 10px;
    padding-top: 12px;
    margin-top: 35px;

}
@media screen and (max-width:1300px) {
    .driver {
        margin-left:10px ;
        margin-right: 10px;
    }
}
.driver span{
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
    text-align: left!important;
}

.level {
    display: flex;
    flex-flow: column;
    margin-left: 30px;
    margin-right: 30px;
    padding-right: 10px;
    padding-top: 12px;
    margin-top: 5px;

}


.driver span{
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
    
}

.persona {
    display: flex;
    flex-flow: column;
    margin-left: 30px;
    margin-right: 30px;
    padding-right: 10px;
    padding-top: 12px;
    margin-top: 5px;
}
@media screen and (max-width:1300px) {
    .persona {
        margin-left:10px ;
        margin-right: 10px;
    }
}
.persona span{
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
    
}

.milestone {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    padding-right:10px;
    padding-top: 12px;
    margin-top: 135px!important;
    position: relative;
}
@media screen and (max-width:1300px) {
    .milestone {
        margin-left:10px ;
        margin-right: 10px;
    }
}
.milestone .milestone-title {
   font-family: 'Poppins';
   font-size: 16px;
   color:#495057;
        
 
}

.persona span{
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
    
}


.weight {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    padding-right:10px;
    padding-top: 12px;
    margin-top: 10px;
    position: relative;
   
}
@media screen and (max-width:1300px) {
    .weight {
        margin-left:10px ;
        margin-right: 10px;
    }
}
.weight span{
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
    
}


.stars {
    width: 100%;
    display: flex;
    flex-flow: row;
    margin-left: 40px;
    position: relative;
    justify-content: flex-end;
}

.stars .star {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
}


.difficulty {
    display: flex; 
    flex-flow: row;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    padding-right: 10px;
    padding-top: 12px;
    margin-top: 10px;
    position: relative;
   
}
@media screen and (max-width:1300px) {
    .difficulty {
        margin-left:10px ;
        margin-right: 10px;
    }
}
.difficulty-title {
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
}

.fires {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    padding-right:10px;
    padding-top: 12px;
    margin-top: 10px;
    position: relative;
}
@media screen and (max-width:1300px) {
    .fires {
        margin-left:10px ;
        margin-right: 10px;
    }
}
.fire {
    width: 100%;
    display: flex;
    flex-flow: row;
    margin-left: 75px;
    position: relative;
    justify-content: flex-end;
}
.fires .fires-title {
    color: white;
}

