.login_container{
    width:100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/image/login-back.png");
    background-color: #39424D;
}
.login_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.login_header{

    display: flex;
    align-items: center;
}
.login-content{
    padding:22px 45px 40px;
    background-color: #fff;
    border-radius: 12px;
    width: 350px;
}
.username{
    width:100%;
}
.username label,.password label{
    display: block;
    font-family: "PoppinsMedium";
    color: #495057;
    font-size: 13px;
}
.username input,.password input{
    width:100%;
    margin-top: 9px;
    padding:8px;
    padding-left: 12px;
    border-radius: 4px;
    color:#495057;
    font-size: 13px;
    border:1px solid #CED4DA;
    font-family: "PoppinsRegular";
}
.password{
    width:100%;
    margin-top: 14px;
    position: relative;
}
.eye-icon{
    position: absolute;
    bottom: -5px;
    right: 0;
}
.loginbutton{
    margin-top: 25px;
    width:100%;
  
}
.loginbutton button{
    display: block;
    width:100%;
    padding:9px 44px;
    background: #34269E;
    border:none;
    border-radius: 20px;
    font-size: 15px;
    font-family: "PoppinsBold";
    color:white;
    text-transform: capitalize;
}
.logo{
    max-width: 700px;
    position: relative;

    
}
.headerimage{
    width: 39%;
    right: 10px;
    position: relative;
}
.login-content-title{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 22px;
    padding-bottom: 20px;
    color: #302F45;
    text-align: center;
}
.forgot-password{
    font-size: 12px;
    font-family: "Rajdhani";
    color: #3E3F5E;
    line-height: 15px;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 0;
}
.verification-resend-code{
    font-size: 12px;
    font-family: "Rajdhani";
    color: #34269E;
    line-height: 15px;
    text-align: center;
    width: 100%;
    border: none;
    background: none;
}
.forgot-password-button{
    font-size: 12px;
    font-family: "Rajdhani";
    color: #34269E;
    line-height: 15px;
    text-align: right;
    font-weight: bold;
    width: 100%;
    border: none;
    background: none;
    padding-top: 15px;
    padding-bottom: 10px;
}