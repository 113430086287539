.svg-polygon{
    width: 279px;
    height: 300px;
    position: relative;
    z-index: 1000;

}
.polygonChartContainer{
position: relative;
}
.svg-polygon polygon{
    width:100%;
    height: 100%;
    fill:none;
    stroke: #f7f7fa;
    stroke-width: 20px;
    stroke-linecap: round;

    

}
.svg-polygon polygon:nth-child(1){
    stroke: #ffffff;
    stroke-width: 40px;
}
.svg-polygon polygon:nth-child(3){
    stroke-dasharray: 835;
   
    stroke: #2ED2E2;
}
.profilepic{
    border-radius: 42%;
    position: absolute;
    left: 28px;
    height: 266px;
    object-fit: cover;
    top: 10px;
    width: 223px;
}
.sortIcon{
    margin-left: 10px;
}
.round{
    stroke-linejoin: round;
}
.student-info{
    display: flex;
    flex-wrap: wrap;
}
.student-info div{
    width: 11%;
    min-width: 136px;
    padding: 0 12px;
    margin-bottom: 15px;
    border-right: 1px solid #707070;
}


.first-col h3{
    font-family: "Poppins";
    font-size:15px !important;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    color: #495057;
}
.first-col p{
    font-family: "Poppins";
    font-size:13px !important;
    color: #74788D;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
}

.forth-student-score .circle-chart h4{
    font-size: 30px !important;
    max-width: 150px;
    text-align: center;
    width: 100%;
}
