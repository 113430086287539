.review-container{
    width:100%;
    display: flex;
    justify-content: center;
}
.review-wrapper{
    background: #1766C1;
    width: 100%;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
}
.review_title{
    width:62%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.review_title h1{
    width: 100%;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    padding-top: 8px;
    font-size: 37px;
    line-height: 40px;

}
@media screen and (max-width:1300px) {
    .review_title h1{
        font-size: 20px;
    }
}
@media(max-width:1435px){

}
.review_title h3{
    width: 100%;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    padding-top: 4px;
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 7px;
}
.review_description{
    width:70%;
    font-size:18px;
    color:white;
    font-family: 'Roboto', sans-serif;
    padding-top: 30px;
}
.review_description h3{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.review_image{
    width: 30%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding-left: 4%;
}
.review_image_img{
    width: 94px;
}
.review_icon{
    width: 90px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 60px;
    font-weight: 600;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:1300px) {
    .review_icon{
        width: 70px;
    }
}
.xp-content-review{
    font-family: 'Roboto', sans-serif;;
    color:black;
    -webkit-text-stroke: 2px white;
    position: relative;
    font-size: 39px;
    font-weight: bold;
    line-height: 40px;

}
@media screen and (max-width:1300px) {
    .xp-content-review{
        font-size: 24px;
    }
}
.xp-text{
    font-family: 'Roboto', sans-serif;
    color:#FF0000;
    -webkit-text-stroke: 2px white;
    padding-left:3px;
    position: relative;
    font-size: 39px;
    font-weight: bold;
    line-height: 40px;
}
@media screen and (max-width:1300px) {
    .xp-text{
        font-size: 26px;
    }
}
.review_icon_exp{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.review_icon_exp img{
    width: 50px;
}
@media screen and (max-width:1300px) {
    .review_icon_exp img{
        width: 40px;
    }
}
.review-edit-button{
    width:30%;
    display: flex;
    align-items: center;
    justify-content: center;
}