
.tab{
    animation: tab 1s linear
}
@keyframes tab {
    0%   {opacity: 0;}
    25%  {opacity: 0;}
    50%  {opacity: .5;}
    75%  {opacity: .8;}
    100% {opacity: 1;}
}