.leaderBoard-container{
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0,0.18);
    padding: 9px;
    justify-content: flex-start;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: 100%;
    max-height: 340px;
    overflow: scroll;
}
.leader-board-item{
    display: flex;
    flex-direction: row;

    align-items: center;
    text-align: center;
    padding:10px 0 ;

}
.leader-board-item-content{
    display: flex;
    flex-direction: row;
}
.leader-board-item-index{
    background-color: #34C38F;
    color: #fff;
    width: 39px;
    display: flex;
    height: 39px;
    justify-content: center;
    align-content: center;
    font-size: 22px;
    border-radius: 50px;
    align-items: center;
    margin-bottom: 13px;
}

.leader-board-item .leader-board-item-name{
    margin: 0;
    padding-left: 11px;
    font-size: 21px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;


}
.leader-board-item-xp-content{
    display: flex;
    flex-direction: row;
}
.leader-board-item-xp-container{
    padding-bottom: 20px;
    border-bottom: 1px solid #BABABA;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    text-align: center;
}
.leader-board-item .leader-board-item-xp{
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #FF4C00;
}
.leader-board-item .leader-board-item-xp-text{
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin-left: 10px;
}