.delete_confirm {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
.delete_confirm_container {
  width: 40%;
  height: 300px;
  background: white;
  border-radius: 15px;
}
.delete_title {
  text-align: center;
  padding-top: 50px;
}
.delete-button {
  text-align: center;
  padding-top: 50px;
}
.confirm_yes,
.confirm_no {
  background: #00c4b8;
  padding: 10px 40px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  font-family: "Poppins";
  border: none;
  border-radius: 50px;
}
.confirm_no {
  background: #ff4b00;
  margin-left: 20px;
}
