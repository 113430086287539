.student-table-container .MuiTableCell-head{
    font-family: 'Poppins' !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    text-align: center !important;
}

.student-table-container .MuiTableCell-body{
    font-family: 'Poppins' !important;
    font-size: 13px !important;
    text-align: center !important;
}
@media (max-width: 1300px) { 
    .student-table-container .MuiTableCell-head{
        font-size: 12px !important;
    }
    .student-table-container .MuiTableCell-body{
        font-size: 12px !important;
    }
}
.student-table-container .MuiTableCell-root{
    padding:8px !important;
    width: 20px;
}
.shop-table-container .MuiTableCell-root.MuiTableCell-head{
    font-weight: 400 !important;
}
.addXp{
    
}
.xpContainer{
    width:100%;
    height: 100%;
    
    position: absolute;
    left:-0;
    top:0;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;

}
 .xpPanle{
    background: #6B6875;
    width: 309px;
    height: 197px;
    border-radius: 25px;
    position: absolute;
    right: 100px;
    z-index: 1000;
}
.xpPanel_header{
    color: white;
    width: 100%;
    text-align: left;
    padding-top: 21px;
    padding-left: 30px;
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 400;
    border-bottom: 1px solid rgba(255,255,255,0.6);
    padding-bottom: 2px;
}
.xp_description{
    text-align: left;
}
.xpDescription{
    width: 235px;
    margin-top: 7px;
    margin-left: 6px;
    padding: 11px 12px;
    border-radius: 50px;
    border: none;
    color: white;
    background: #707070;
    font-size: 11px;


}
.xpDescription:focus{
    border:none;
    outline: none;
}
.xpDescription::placeholder{
    color:white;
}
.addxpbutton{
    background: #F0C26A;
    color: white;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border-radius: 50px;
    border: none;
    font-size: 34px;
    line-height: 40px;
    bottom: 46px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    right: 19px;
    padding-bottom: 7px;

}
.xpDate{
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 11px 27px;


}
.date_text{
    font-size: 20px;
    letter-spacing: 5px;
}
.MuiTableCell-head, .MuiTableCell-root{
    padding: 13px 2px;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
}
.student-table-container .MuiTableRow-root .student-name-table{
    border-bottom: 0;
    text-align: start !important;

}
.student-name-list{
    color: #495057;
     font-size: 15px;
      text-decoration: none;
}
 @media (max-width: 1300px) { 
    .student-name-list{
    font-size: 13px;
    }
}
.exclamation-icon{
    background-color: rgb(229, 9, 102);
    border-radius: 50px;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 5px;
    right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}