.experience-details-action{
    width:100%;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.experience-details-action .exp-icon{
    position: absolute;
    top:-30px;
    right: 30px;
}
.xp{
    padding-left: 10px;
    margin-top: -20px;
}
.xp span:nth-child(1){
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 700;
}
.xp span:nth-child(2){
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 700;
    color:#FF0000;
}

.experience-title{
    padding-top: 20px;
    
}
.experience-title input{
    background:transparent;
    color:white;
    border:none;
    outline: none;
    font-family: 'Poppins';
    font-weight: 900;
    text-align: center;
    font-size: 24px;
    width: 100%;
}
@media screen and (max-width:1300px) {
    .experience-title input{
        font-size: 18px;
    }
}
.experience-title-subtitle{
    margin-top: 30px;
}

.experience-title-subtitle input{
    background:transparent;
    color:white;
    border:none;
    outline: none;
    font-family: 'Poppins';
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    width: 100%;
}
@media screen and (max-width:1300px) {
    .experience-title-subtitle input{
        font-size: 16px;
    }
}
.experience-title-description{
    margin-top: 20px;
   
}
.experience-title-description textarea{
    background-color: transparent;
    border: none;
    outline: none;
    overflow: hidden;
    color:white;
    font-family: 'Poppins';
    text-align: center;
    text-align: left;
    width:80%;
    height: 90px;
    font-size: 15px;
    font-weight: 600;
}
@media screen and (max-width:1300px) {
    .experience-title-description textarea{
        font-size: 12px;
    }
}
.experience-title-link-content{
    display: flex;
    justify-content: center;
    align-items: center;
}
.experience-title-link-content p{
        margin: 0;
        padding-left: 10px;
        color: #fff;
        font-size: 19px;
        letter-spacing: 1px;
        font-weight: 700;
        text-shadow: -1px 0 #5cff88, 0 1px #5cff88, 1px 0 #5cff88, 0 -1px #5cff88;
}

.experience-location span{
    color:white;
    font-family: "Poppins";
    font-size:12px;
    -webkit-text-stroke: 0.4px #5CFF88;
    padding-left: 10px;
}
.exp-setting{
    width:100%;
    background-color: white;
    min-height: 640px;
    border-radius: 10px;
    text-align: left;
    padding-top: 10px;
    padding-left: 10px;
    height:100%;
    position: relative;
}
.exp-setting button{
    background: none;
    border: none;
    position: absolute;
    right: 12px;
    z-index: 2;
    transition-duration: 0.3s;
    transform: rotate(180deg);
}
.boxActive .exp-setting button{
    transform: rotate(0deg);
}

.motivational-title{
    font-family: 'Poppins';
    font-size: 25px;
    line-height: 25px;
    padding-bottom: 24px;
    max-width: 90%;
    
}
@media screen and (max-width:1300px) {
    .motivational-title{
        font-size: 18px;
    }
}
.xp-comp{
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    padding-top: 12px;
}
.xp-comp input{
    width:66px;
    border:1px solid gray;
    border-radius: 5px;
    text-align: center;
}
@media screen and (max-width:1300px) {
    .xp-comp input{
        width: 57px;
        height: 30px;
    }
}
.xp-comp span{
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
    
}
@media screen and (max-width:1300px) {
    .xp-comp span{
        font-size: 13px;
    }
}
.submition{
    font-family: 'Poppins';
    font-size: 24px;
    color: #495057;
    padding-top: 10px;
}
@media screen and (max-width:1300px) {
    .submition{
        font-size: 20px;
    }
}
.submition p{
    margin-bottom: -9px;
}
.submition span{
    font-size: 10px;
}
.auto-complition{
    padding-top:4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}

.auto-complition .auto-title{
    font-family: 'Poppins';
    font-size: 14px;
}
.at-home{
    padding-top:4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}

.at-home .auto-title{
    font-family: 'Poppins';
    font-size: 14px;
}
.location-aware{
    padding:10px 5px 7px;
    font-family: 'Poppins';
    font-size:15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.location-aware .location-title{
    font-size: 20px;
}
.text-link{
    padding:10px 5px 7px;
    font-family: 'Poppins';
    font-size:15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.text-link .text-link-title{
    font-size: 20px;
}
.pick-location-container{
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.pick-location{
    display: inline-block;
    border: 2px solid #37C68F;
    padding: 0px 30px;
    line-height: 20px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 12px;
}
.edit-location{
    border:3px solid #37C68F;
    width: 26px;
    height: 26px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}
.location-restrictive-container{
    padding-top: 6px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.location-restrictive{
    font-family: "Poppins";
    font-size: 13px;
}
.bonus-multiplier-container{
    padding-top: 6px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.bonus-multiplier{
    font-family: 'Poppins';
    font-size: 13px;
    padding-right: 15px;
}
.bonus-multiplier-container input{
    width:50px;
    border:1px solid gray;
    border-radius: 5px;
    text-align: center;
}
.notification-Container{
   padding: 0 10px;
    
}
.notification-title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notification-title{
    font-family: "Poppins";
    font-size: 20px;
}
.notification-title{
    
    padding-top:10px;
}
.notification-content-container{
   

}
.start{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px;
}
.mid{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px;
}
.pass-due{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px;
}
.start-not{
    
    font-family: 'Poppins';
    font-size: 15px;
}
.mid-not{
    
    font-family: 'Poppins';
    font-size: 15px;
}
.pass-due{
    
    font-family: 'Poppins';
    font-size: 15px;
}
.completion-action-container{
    padding-top: 10px;
}
.completion-button{
    background: #5CFF88;
    border:none;
    padding:10px 16%;
    color:white;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: bold;
    border-radius: 8px;
}
.completion-action-container p{
    font-family: 'Poppins';
    font-size: 15px;
    color:black;
    font-weight: bold;
}
.pick-pic{
    cursor: pointer;
    width: 250px;
}
@media screen and (max-width:1300px) {
    .pick-pic{
        width: 80%;
        max-width: 250px;
    }
}
.experience_card_list_container{
    width: 100%;
    background: white;
    padding-top: 15px;
    
}
.exppic{
    width: 50px;
    height: 50px;
    border-radius: 50%;
   
}
h3{
    font-family: 'Poppins';
    font-size: 9px;
    font-weight: bold;
}
.st-info p{
    padding-right: 10px;
    font-family: 'Poppins';
    font-size: 12px;

}
.st-info{
    padding: 25px 22px;
    position: relative; 
    overflow: hidden;
    display: flex;
    border-radius:12px !important;
    justify-content: space-between;

}
.st-info-container{
    height: 260px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}
.student-info-tag-container{
    position: relative;
    top: -7px;
}
.student-info-tag-container h3{
    font-family: "Poppins";
    font-size: 15px !important;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    color: #495057;
    position: absolute;
    top: -35px;
}
.student-info-tags{
  border: 1px solid #CED4DA;
  border-radius: 22px;  
  padding: 20px;
  width: 95%;
  height: 158px;
  min-width: 210px;
  display: flex;
  justify-content: space-between;
}
.student-info-tags p {
    width: 120px;
    height: 29px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #CED4DA;
    border-radius: 5px;  
    color: #74788D;
    font-size: 12px;
    font-family: 'Poppins';
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.st-info-title{
    font-size: 32px;
    color: #495057;
    font-family: 'Poppins';
    font-weight: 500;
    padding-right: 15px;
}
@media(max-width:1600px){
 .st-info-container{
     height: auto;
 }
}

.student-cahrt-filter-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}
@media(max-width:1600px){
    .st-info{
         height:auto;

    }
    .fourth-student-info{
        padding-left: 0 !important;
        transform: scale(0.8);
    }
    .forth-student-scores{
        margin-left: -86px;
    }
}
@media(max-width:1330px){
    .forth-student-scores{
        width: 100% !important;
    }
    .st-info{
        flex-wrap: wrap;
   }
}
.thrid-student-info{
    padding-left: 68px;
}
.thrid-student-info h3{
    font-size: 15px;
    font-weight: 500;
}
.thrid-student-info p{
    font-family: "Poppins";
    font-size:13px !important;
    color: #74788D;  
    margin-bottom: 24px;
}

.edit_experience{
    width:100%;
    padding:30px 100px;
    background:white;
    display: flex;
    justify-content: center;
    
}

.edit_experience .unpublish,.edit_experience .update{
    padding:10px 25px;
    color:white;
    background: #0078FF;
    border:none;
    border-radius: 50px;
    font-weight: bold;
    font-size:16px;
    font-family: "Poppins";
    margin-right: 20px;
}
.edit_experience .delete{
    background: #0078FF ;
    width:46px;
    height: 46px;
    border-radius: 50px;
    border:none;
    margin-right: 20px;
}
.edit_experience .update{
    background: #34C38F;
}
.filter-container{
    width:300px;
    height: 36px;
    margin-left: 9%;
    background:#707070;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
@media screen and (max-width:1300px) {

    .filter-container{
        width:260px;
        height: 30px;
        margin-left: 3%;
    }
}
.filter-container .publish,.filter-container .saved{
     flex:1;
    text-align: center;
    
    height: 100%;
    border-radius: 50px;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5 ease-in-out;
    position: relative;
    cursor: pointer;
}
.filter-container .publish::before{
    content: 'Publish';
    display: block;
    color:white;
    position: absolute;
    z-index:50;
}
.filter-container .saved::before{
    content: 'Saved';
    display: block;
    color:white;
    position: absolute;
    z-index:50;
}
.expsActive{
    width:50%;
    height: 100%;
    position:absolute;
    background: rgba(0,0,0,.5);
    top:0;
    left:0;
    border-radius: 50px;
    z-index:40;
    transition: all 0.3s ease-in-out;
}
.container-content{
    width: 100%;
    display: flex;
    justify-content: center;
}
.container-content-wrapper{
    width: 97.5%;
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
}

.experience_type_content{
    width:40%;
    height: 100%; 
    min-width: 480px;
}

.analystics{
    width: 70%;
    height: 100%;
    padding-left: 17px;
}

.analystics.experience-without-image{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 45px 0;
}

.analystics.experience-without-image > div{ 
    width: 48%;
    min-width: 470px;
}
@media(max-width:1230px){
    .analystics.experience-without-image > div{ 
        width: 100%;
        margin: 12px;
    }
}
.st-info-about-box{
    margin-bottom: 10px;
    width: 100%;
    height: 44px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 15px;
}

.st-info-about-box h5{
    font-size: 35px;
    line-height: 45px;
    margin: 0;
}

.st-info-about-box sup , .st-info-about-box sub{
    font-size: 20px;
}
.st-info-about-box sub{
    bottom: -0.1em;
    padding-right: 5px;
}
.st-info-about-items{
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;

}

.st-info-first-generation{
    border:4px solid #00B114;
}

.st-info-first-generation h5{
    color: #00B114;
}
.st-info-about-enfp{
    border:4px solid #FFB100 ;
}
.st-info-about-enfp h5{
    color: #FFB100;
}
.st-info-about-grit{
    border:4px solid #C74545 ;
}
.st-info-about-grit h5{
    color: #C74545;
}
.st-info-about-burns{
    border:4px solid #003267 ;
}
.st-info-about-burns h5{
    color: #003267;
}

.st-info-about-item{
    border-radius: 12px;
    height: 95px;
    width: 29%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);
    padding: 9px;
    margin: 0 2% 22px;
    justify-self: center;
    text-align: start; 
}
.st-info-about-item h3{
    font-size: 22px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    margin-bottom: 3px;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.st-info-about-item p{
    font-size: 18px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 400;
    margin-bottom: 0;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
} 
.st-info-about-interests{
    border-radius: 12px;
    height: 315px;
    width: 44%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);
    padding: 9px;
    margin: 0 8px;
    margin-top:4%;
}
@media screen and (max-width:1300px) {
    .st-info-about-interests{
        height: fit-content;
        min-height: 109px;
        margin-bottom: 15px;
    }
}

.st-info-about-interests h3{
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Rajdhani', sans-serif;
}
.st-info-about-interests-item{
    border:1px solid #CED4DA;
    border-radius: 49px;
    width: fit-content;
    padding: 0 10px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    height: 35px;
    display: flex;
    text-align: center;
    margin: 4px;
    align-items: center;
    min-width: 70px;
    justify-content: center;
}
.st-info-about-social-media{
    border-radius: 12px;
    height: 315px;
    width: 44%;
    margin: 0 8px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
    padding: 9px;
    margin-top:4%;
}
@media screen and (max-width:1300px) {
    .st-info-about-social-media{
        height: fit-content;
        min-height: 109px;
        margin-bottom: 15px;
    }
}

.st-info-about-social-media h3{
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Rajdhani', sans-serif;
}
.st-info-about-social-media-item{
    border:1px solid #CED4DA;
    border-radius: 49px;
    width: fit-content;
    padding: 0 13px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    height: 40px;
    display: flex;
    text-align: center;
    margin: 5px;
    align-items: center;
    justify-content: center;
}
.st-info-about-social-media-content , .st-info-about-interests-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 250px;
    align-content: flex-start;
}
@media screen and (max-width:1300px) {
    .st-info-about-social-media-content , .st-info-about-interests-content{
        height: fit-content;
    }
}
.st-info-about-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 1%;
    margin-top: 45px;
}
.st-info-about-right-side{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.st-info-team-content{
    border-radius: 12px;

    width: 250px;
    box-shadow: 0 3px 6px rgba(0, 0, 0,0.18);
    padding: 9px;
    justify-content: flex-start;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.st-info-team-content-title{
    font-family: 'Rajdhani', sans-serif;
    font-weight: bold;
    font-size: 30px;
}
.st-info-team-data{
    width: 28%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.st-info-leader-board{
    width: 28%;
    margin-left: 20px;
}
.st-info-leader-board-title{
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0,0.18);
    padding: 9px;
    justify-content: flex-start;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #113163;
    font-size: 55px;
    font-weight: 600;
    line-height:50px;
    font-family: 'Rajdhani', sans-serif;
}
@media screen and (max-width:1300px) {
    .st-info-leader-board-title{
        font-size: 36px;
    }
}
.student-usage .st-usage-item{
  width: 50%;
}
.student-usage .st-usage-item h3{
    font-size: 21px;
    font-weight: 400;
    color: #495057;
}
.student-usage .st-usage-item p {
    font-size: 18px;
    font-weight: 300;
    color: #74788D;
}
.student-usage{
    margin-top: 25px;
    flex-wrap: wrap;
    display: flex;
}
.student-usage .circle-chart h4{
    max-width: 51%;
    text-align: center;
}
.st-info-experience-box{
    width: 49%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0,0.18);
    padding: 9px;
}

.st-info-experience-box-container{
    display: grid;
    grid-template-columns:1fr 1fr 1.5fr 1fr;
    text-align: center;
    margin-bottom: 7px;
}
.st-info-experience-box-container h4{ 
    font-size: 18px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: bold;
}
.category-active{
    background-color:#4ed8a6;
}
@media(max-width:1500px){
    .experience-empty-col{
        display: none !important;
    }
    .experience-responsive-col{
        width: 33% !important;
    }
}
.info-col{
    display: flex;
    flex-direction: wrap;
    border-radius: 8px;
    margin: 1rem;
    box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
    padding: 13px;
    flex-wrap:wrap;
    min-width: 17%; 
    max-width: 18%;
    min-height: 175px;
    margin-right: 10px;
    justify-content: space-between;
    overflow: hidden;
}



.info-student-project-title{
    font-family: "Poppins";
    font-size: 15px !important;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    color: #495057;
    position: absolute;
    top: -28px;
}

.info-col div{ 
    padding: 3px;
}


.driver {
    display: flex;
    flex-flow: column;
    margin:0 30px;
    padding-right: 10px;
    padding-top: 12px;
    margin-top: 35px;

}
@media screen and (max-width:1300px) {
    .driver {
        margin-left:10px ;
        margin-right: 10px;
    }
}
.driver span{
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
    text-align: left!important;
}

.level {
    display: flex;
    flex-flow: column;
    margin:0 30px;
    padding-right: 10px;
    padding-top: 12px;
    margin-top: 5px;

}

@media screen and (max-width:1300px) {
    .level {
        margin-left:10px ;
        margin-right: 10px;
    }
}
.driver span{
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
    
}

.persona {
    display: flex;
    flex-flow: column;
    margin:0 30px;
    padding-right: 10px;
    padding-top: 12px;
    margin-top: 5px;
}
@media screen and (max-width:1300px) {
    .persona {
        margin-left:10px ;
        margin-right: 10px;
    }
}
.persona span{
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
    
}

.milestone {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    
    padding-right: 10px;
    padding-top: 12px;
    margin-top: 50px!important;
   
}

.persona span{
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
    
}

.weight {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin:0 30px;
    padding-right:10px;
    padding-top: 12px;
    margin-top: 10px;
   
}
@media screen and (max-width:1300px) {
    .weight {
        margin-left:10px ;
        margin-right: 10px;
        flex-wrap: wrap;
    }
}
.weight span{
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
    
}


.stars {
    width: 100%;
    display: flex;
    flex-flow: row;
    margin-left: 40px;
    
    justify-content: flex-end;
}

.stars .star {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
}


.difficulty {
    display: flex; 
    flex-flow: row;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    padding-right: 10px;
    padding-top: 12px;
    margin-top: 10px;
    position: relative;
   
}
@media screen and (max-width:1300px) {
    .difficulty {
        margin-left:10px ;
        margin-right: 10px;
    }
}
.difficulty-title {
    font-family: 'Poppins';
    font-size: 16px;
    color:#495057;
}

.fires {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    padding-right:10px;
    padding-top: 12px;
    margin-top: 10px;
    position: relative;
}

.fire {
    width: 100%;
    display: flex;
    flex-flow: row;
    margin-left: 75px;
    position: relative;
    justify-content: flex-end;
}
@media screen and (max-width:1300px) {
    .fire {
        margin-left:30px ;
    }
}
.fires .fires-title {
    color: white;
}


@media(max-width:1350px){
    .info-col-container{
        flex-wrap: wrap;
    }
    .info-col-container > div{
        width: fit-content;
        min-width: 30%;
        margin: 35px 10px;
    }

}
.dark-input{
    max-width: 280px;
    height: 30px;
    border: 1px solid #9B9B9D;
    padding: 9px;
    font-size: 13px;
    margin: 8px 0;
    background-color: #EBEBEB;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgb(0 0 0 / 20%);
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: none !important;
}
input[type="date"]::after{
    content: "";
    background-image:  url("../assets/image/date.png") !important;
    background-size: cover;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 7px;
    pointer-events: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background-image: none !important;
}
input[type="time"]::after{
    content: "";
    background-image:  url("../assets/image/clock.png") !important;
    background-size: cover;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 7px;
    pointer-events: none;
}
input[type="time"], input[type="date"]{
    position: relative;
}

@media screen and  (max-width:1300px) {
    .experience-item-button{
        display: none;
    }
}
