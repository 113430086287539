#clockdiv{ 
   display: flex;
}
#clockdiv > div{
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}
#clockdiv div > span{
    color: #B7B7BC;
    font-size: 27px;
    font-family: "Rajdhani";
    font-weight: bold;
    line-height: 34px;
}
@media screen and (max-width:1300px) {
    #clockdiv div > span{
        font-size: 20px;
    }
}
.smalltext{
    color: #B7B7BC;
    font-size: 27px;
    font-family: "Rajdhani";
    font-weight: bold;
    line-height: 34px;
    padding: 0 5px;
}
@media screen and (max-width:1300px) {
    .counter-responsive .smalltext{
        font-size: 0;
    }
    .counter-responsive .smalltext:first-letter {
        font-size: 16px;
      }
}

 
