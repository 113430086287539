.gradegy-organization .MuiTableCell-head,.gradegy-organization .MuiTableCell-root{
    padding: 13px 2px;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
}
.gradegy-organization .MuiTable-root{
    width: 94%;
}
