.setting_icon{
    border:2px solid #5F6475;
}
.sidebar_item_name{
    font-size: 14;
    padding-left: 10px;
    font-family: "PoppinsLight";
}
.part-of-student h4{
    color:white;
    font-family: "PoppinsLight";
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 1.5rem;
}
.part-of-home{

    margin-bottom: 1.5rem;
}

.part-of-admin h4{
    margin-top: 3rem;
    color:white;
    font-family: "PoppinsLight";
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 1.5rem;
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}