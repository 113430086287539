.st-info-team-data .crystal-student{
    height: 66px;
    width: 100%;
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0,0.18);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
}
.st-info-team-data .crystal-student img{
    width: 23px;
}
.st-info-team-data .crystal-number{
    font-family: 'Rajdhani', sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    padding-left: 10px;
}
.st-info-team-data .crystal{
    font-family: 'Rajdhani', sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    padding-left:50px;
}