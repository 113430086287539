.gradegy-advane-check-box{
    padding: 0px 22px 2px 6px !important;
}
.gradegy-advane-check-box > div:first-of-type {
    box-shadow: none !important;
    height: 20px;
    display: contents !important;
    font-family: "Poppins";
}

.gradegy-advane-check-box > div > div:first-child{
    font-weight: 400;
    font-weight:"Poppins";

}
.gradegy-advane-check-box > div:first-of-type  > div:last-child{
    display: none !important;
}
.gradegy-advane-check-box > span:first-child{
    font-size: 10px;
    font-family: "Poppins";
}
.gradegy-advane-check-box input{
    box-shadow: none !important;

    font-family: "Poppins";
    min-width: 129px;
    font-size: 10px;
    margin-top:-6px !important;
}
